const serviceUrlPro = 'https://task.xiaowuxiongdi.com/api'
const serviceUrlUat = 'http://studio.xiaowukeji.cn/api'

// const serviceUrlDev = 'http://47.56.181.187:8091'

// const serviceUrlDev = 'http://172.16.26.10:8091'

// const serviceUrlDev = 'http://172.16.26.189:8091'

// const serviceUrlDev = 'http://172.16.26.163:8091'

// const serviceUrlDev = 'http://172.16.25.105:8091' // dy
// const serviceUrlDev = 'http://172.16.25.10:8091' // dy本地

// const serviceUrlDev = 'http://172.16.24.200:8097' // cs

// const serviceUrlDev = 'http://172.16.26.127:8091' // dd

// const serviceUrlDev = 'http://172.16.25.179:8091' // 新测试
// const serviceUrlDev = 'http://172.16.24.200:8097' // cs
// const serviceUrlTest = 'http://172.16.24.200:8097' // cs
// const serviceUrlTest = 'http://172.16.25.179:8091' // cs新的
const serviceUrlDev = 'http://172.16.24.200:8097' // cs
const serviceUrlTest = 'http://172.16.24.200:8097' // cs

// const serviceUrl = 'http://172.16.26.225:8091' // 旧cs

const envServiceUrlMap = {
  development: serviceUrlDev,
  production: serviceUrlPro,
  buildTest: serviceUrlTest,
  buildUat: serviceUrlUat,
}

// console.log(process.env.NODE_ENV)
const serviceUrl = envServiceUrlMap[process.env.NODE_ENV]

// 版本信息，更新版本后使用户刷新浏览器以更新缓存
const version = '78'
const oldVersion = '68'

export default {
  serviceUrl,

  // Endpoints
  loginEndpoint: `${serviceUrl}/sso/doLoginByTicket`,
  userInfoEndpoint: `${serviceUrl}/sso/userInfo`,
  isLoginEndpoint: `${serviceUrl}/sso/isLogin`,
  ssoAuthUrlEndpoint: `${serviceUrl}/sso/getSsoAuthUrl`,
  logoutEndpoint: `${serviceUrl}/sso/logout`,

  routeEndpoint: `${serviceUrl}/base/route`,
  menuEndpoint: `${serviceUrl}/base/menu`,

  // routeEndpoint: '/base/route',
  // menuEndpoint: '/base/menu',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageVersionKeyName: 'version',
  version,
}
